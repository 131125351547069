/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import {
  TRemindPasswordByUserIdModel,
  IRemindPasswordByUserIdRequest,
  IMappers,
  IRemindPasswordByUserIdResponseError,
  TRemindPasswordByUserIdResponse,
} from './types';

const defaultConfig: TRemindPasswordByUserIdModel = {
  apiType: 'public',
  assertStatusCodes: [204, 400],
  method: 'POST',
  microserviceName: 'users',
  pathApi: '/v1/remind-password-by-user-id/',
  hostType: 'api',
} as TRemindPasswordByUserIdModel;

function createRemindPasswordByUserIdModel(parameters: IRemindPasswordByUserIdRequest): TRemindPasswordByUserIdModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IRemindPasswordByUserIdOptions<TResponse204, TResponse400> {
  httpApi: IHttpApi;
  parameters: IRemindPasswordByUserIdRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse204, TResponse400>;
}

async function fetchRemindPasswordByUserId<TResponse204, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IRemindPasswordByUserIdOptions<TResponse204, TResponse400>): Promise<
  TResponse204 | TResponse400 | TRemindPasswordByUserIdResponse
> {
  const { response, statusCode } = await httpApi.fetch(createRemindPasswordByUserIdModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 204) {
      return mappers[204]();
    }

    if (statusCode === 400) {
      return mappers[400](response as IRemindPasswordByUserIdResponseError);
    }
  }

  return { response, statusCode } as TRemindPasswordByUserIdResponse;
}

export { defaultConfig, createRemindPasswordByUserIdModel, fetchRemindPasswordByUserId };
