import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setPage } from '../../actions/page';
import { Authentication } from '../../components/Authentication';
import { selectIsRedesignAuthExperimentEnabled } from '../../selectors/experiments/selectIsRedesignAuthExperimentEnabled';
import { selectPageContainer, selectReturnUrl } from '../../selectors/page';
import { EPageContainer, EPageType } from '../../types/page';
import { TThunkDispatch } from '../../types/redux/thunk';
import { useApplicationContext } from '../../utils/applicationContext';

export const AuthenticationContainer: React.FC = () => {
  const { config, authenticationContext } = useApplicationContext();
  const dispatch = useDispatch<TThunkDispatch>();
  const disabledSocials = config.get<string[]>('authentication.disabledSocials') || [];
  const container = useSelector(selectPageContainer);
  const returnUrl = useSelector(selectReturnUrl);
  const isRedesignExperimentEnabled = useSelector(selectIsRedesignAuthExperimentEnabled);

  const handleSetPage = React.useCallback((type: EPageType) => {
    dispatch(setPage({ type }));
  }, []);

  if (container !== EPageContainer.Authentication) {
    return null;
  }

  return (
    <Authentication
      returnUrl={returnUrl}
      authenticationContext={authenticationContext}
      disabledSocials={disabledSocials}
      setPage={handleSetPage}
      isRedesignExperimentEnabled={isRedesignExperimentEnabled}
    />
  );
};
